
/**** Colors ****/
:root {
    --_000000: #000000;
    --_333333: #333333;
    --_666666: #666666;
    --_949494: #949494;
    --cccccc: #cccccc;
    --e6e6e6: #e6e6e6;
    --_1k-gray: #d4d4d0;
    --alert-red: #d50032;
    --atlantic-amethyst: #6244bb;
    --dusk: #b6b8dc;
    --global-services: #25282a;
    --green: #208500;
    --mp-platinum-gray: #7c848a;
    --orange: #f46335;
    --pacific-blue: #00b2a9;
    --platinum-gray: #7c878e;
    --premier-gold: #9f7d23;
    --premier-silver: #c1c6c8;
    --premium-purple: #572c5f;
    --rhapsody-blue: #0c2340;
    --sky-blue: #69b3e7;
    --steel-blue: #41748d;
    --united-blue: #0033a0;
    --yellow: #edb72b;
}
/* close :root */

/**** Fonts ****/
:root {
    /** open-sans-body **/
    --open-sans-body-font-size: 18px;
    --open-sans-body-color: #000000;
    --open-sans-body-letter-spacing: 0;
    --open-sans-body-line-height: 24px;
    --open-sans-body-font-family: Open Sans;
    --open-sans-body-font-weight: 400;
    --open-sans-body-text-align: left;
    /** open-sans-body-small **/
    --open-sans-body-small-font-size: 16px;
    --open-sans-body-small-color: #000000;
    --open-sans-body-small-letter-spacing: 0;
    --open-sans-body-small-line-height: 22px;
    --open-sans-body-small-font-family: Open Sans;
    --open-sans-body-small-font-weight: 400;
    --open-sans-body-small-text-align: left;
    /** open-sans-h1 **/
    --open-sans-h1-font-size: 32px;
    --open-sans-h1-color: #000000;
    --open-sans-h1-letter-spacing: 0;
    --open-sans-h1-line-height: 40px;
    --open-sans-h1-font-family: Open Sans;
    --open-sans-h1-font-weight: 600;
    --open-sans-h1-text-align: left;
    /** open-sans-h2 **/
    --open-sans-h2-font-size: 28px;
    --open-sans-h2-color: #000000;
    --open-sans-h2-letter-spacing: 0;
    --open-sans-h2-line-height: 34px;
    --open-sans-h2-font-family: Open Sans;
    --open-sans-h2-font-weight: 600;
    --open-sans-h2-text-align: left;
    /** open-sans-h3 **/
    --open-sans-h3-font-size: 24px;
    --open-sans-h3-color: #000000;
    --open-sans-h3-letter-spacing: 0;
    --open-sans-h3-line-height: 28px;
    --open-sans-h3-font-family: Open Sans;
    --open-sans-h3-font-weight: 600;
    --open-sans-h3-text-align: left;
    /** open-sans-h4 **/
    --open-sans-h4-font-size: 20px;
    --open-sans-h4-color: #000000;
    --open-sans-h4-letter-spacing: 0;
    --open-sans-h4-line-height: 24px;
    --open-sans-h4-font-family: Open Sans;
    --open-sans-h4-font-weight: 600;
    --open-sans-h4-text-align: left;
    /** open-sans-h5 **/
    --open-sans-h5-font-size: 18px;
    --open-sans-h5-color: #000000;
    --open-sans-h5-letter-spacing: 0;
    --open-sans-h5-line-height: 18px;
    --open-sans-h5-font-family: Open Sans;
    --open-sans-h5-font-weight: 600;
    --open-sans-h5-text-align: left;
    /** open-sans-h6 **/
    --open-sans-h6-font-size: 13px;
    --open-sans-h6-color: #000000;
    --open-sans-h6-letter-spacing: 0;
    --open-sans-h6-line-height: 18px;
    --open-sans-h6-font-family: Open Sans;
    --open-sans-h6-font-weight: 400;
    --open-sans-h6-text-align: left;
}
/* close :root */

.open-sans-body {
    font-size: var(--open-sans-body-font-size);
    color: var(--open-sans-body-color);
    letter-spacing: var(--open-sans-body-letter-spacing);
    line-height: var(--open-sans-body-line-height);
    font-family: var(--open-sans-body-font-family);
    font-weight: var(--open-sans-body-font-weight);
    text-align: var(--open-sans-body-text-align);
}

.open-sans-body-small {
    font-size: var(--open-sans-body-small-font-size);
    color: var(--open-sans-body-small-color);
    letter-spacing: var(--open-sans-body-small-letter-spacing);
    line-height: var(--open-sans-body-small-line-height);
    font-family: var(--open-sans-body-small-font-family);
    font-weight: var(--open-sans-body-small-font-weight);
    text-align: var(--open-sans-body-small-text-align);
}

.open-sans-h1 {
    font-size: var(--open-sans-h1-font-size);
    color: var(--open-sans-h1-color);
    letter-spacing: var(--open-sans-h1-letter-spacing);
    line-height: var(--open-sans-h1-line-height);
    font-family: var(--open-sans-h1-font-family);
    font-weight: var(--open-sans-h1-font-weight);
    text-align: var(--open-sans-h1-text-align);
}

.open-sans-h2 {
    font-size: var(--open-sans-h2-font-size);
    color: var(--open-sans-h2-color);
    letter-spacing: var(--open-sans-h2-letter-spacing);
    line-height: var(--open-sans-h2-line-height);
    font-family: var(--open-sans-h2-font-family);
    font-weight: var(--open-sans-h2-font-weight);
    text-align: var(--open-sans-h2-text-align);
}

.open-sans-h3 {
    font-size: var(--open-sans-h3-font-size);
    color: var(--open-sans-h3-color);
    letter-spacing: var(--open-sans-h3-letter-spacing);
    line-height: var(--open-sans-h3-line-height);
    font-family: var(--open-sans-h3-font-family);
    font-weight: var(--open-sans-h3-font-weight);
    text-align: var(--open-sans-h3-text-align);
}

.open-sans-h4 {
    font-size: var(--open-sans-h4-font-size);
    color: var(--open-sans-h4-color);
    letter-spacing: var(--open-sans-h4-letter-spacing);
    line-height: var(--open-sans-h4-line-height);
    font-family: var(--open-sans-h4-font-family);
    font-weight: var(--open-sans-h4-font-weight);
    text-align: var(--open-sans-h4-text-align);
}

.open-sans-h5 {
    font-size: var(--open-sans-h5-font-size);
    color: var(--open-sans-h5-color);
    letter-spacing: var(--open-sans-h5-letter-spacing);
    line-height: var(--open-sans-h5-line-height);
    font-family: var(--open-sans-h5-font-family);
    font-weight: var(--open-sans-h5-font-weight);
    text-align: var(--open-sans-h5-text-align);
}

.open-sans-h6 {
    font-size: var(--open-sans-h6-font-size);
    color: var(--open-sans-h6-color);
    letter-spacing: var(--open-sans-h6-letter-spacing);
    line-height: var(--open-sans-h6-line-height);
    font-family: var(--open-sans-h6-font-family);
    font-weight: var(--open-sans-h6-font-weight);
    text-align: var(--open-sans-h6-text-align);
}

.united-blue {
    color: #0033A0;
}

.rhapsody-blue {
    color: #002244;
}

.runway-gray {
    color: #d4d4d0;
}

.dusk {
    color: #b6b8dc;
}

.dusk-light {
    color: #e2e3f1;
}

.premium-purple {
    color: #572C5f;
}

.platinum-gray {
    color: #7c878e;
}

.pacific-blue {
    color: #00b2a9;
}

.sky-blue {
    color: #69b3e7;
}

.champagne {
    color: #CEB888;
}

.warm-gray {
    color: #796e65;
}

.atlantic-amethyst {
    color: #6244bb;
}

.spirit-gold {
    color: #ffc658;
}

.ecoskies-green {
    color: #1B7742;
}

.go-green {
    color: #78BE20;
}

.basic-economy {
    color: #f16321;
}

.october-pink {
    color: #E06287;
}

.alert-red {
    color: #d50032;
}

.united-blue-bg {
    background-color: #0033A0;
}

.rhapsody-blue-bg {
    background-color: #002244;
}

.runway-gray-bg {
    background-color: #d4d4d0;
}

.dusk-bg {
    background-color: #b6b8dc;
}

.dusk-light-bg {
    background-color: #e2e3f1;
}

.premium-purple-bg {
    background-color: #572C5f;
}

.platinum-gray-bg {
    background-color: #7c878e;
}

.pacific-blue-bg {
    background-color: #00b2a9;
}

.sky-blue-bg {
    background-color: #69b3e7;
}

.champagne-bg {
    background-color: #CEB888;
}

.warm-gray-bg {
    background-color: #796e65;
}

.atlantic-amethyst-bg {
    background-color: #6244bb;
}

.spirit-gold-bg {
    background-color: #ffc658;
}

.ecoskies-green-bg {
    background-color: #1B7742;
}

.go-green-bg {
    background-color: #78BE20;
}

.basic-economy-bg {
    background-color: #f16321;
}

.october-pink-bg {
    background-color: #E06287;
}

.alert-red-bg {
    background-color: #d50032;
}

.activate-button {
    color: white;
    background-color: #6244bb;
    border: 1px solid #6244bb;
}

.undo-button {
    color: white;
    background-color: #b6b8dc;
}

.activate-button:hover {
    background-color: #572C5f;
    color: white;
    border: 1px solid #572C5f;
}

.passive-active-button {
    color: #572C5f;
    background-color: white;
    border: 1px solid #572C5f;
}

.passive-active-button:hover {
    color: white;
    background-color: #572C5f;
    border: 1px solid #572C5f;
}

.undo-button:hover {
    background-color: #572C5f;
    color: white;
}

.success-green {
    background-color: #a3c8b3;
    color: #082313;
}

.error-red-bg {
    background-color: #ee99ad;
    color: #550014;
}

.disabled-empl {
    color: #7c878e;
    cursor: not-allowed;
}