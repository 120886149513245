/*Main CSS*/
html, body {
    background-color: #FFFFFF;
    height: 100%;
}

html {
    overflow-y: scroll;
}

.main-wrap {
    padding-top: 178px;
    margin: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    overflow-x: clip;
}

.main-content {
    flex: 1 1 auto;
    /* background-color: #D4D4D0; */
    padding: 10px;
    padding-left: 6px;
    padding-right: 6px;
}

.header-text-link {
    text-decoration: none;
    color: white !important;
}

.header-text-link:hover {
    text-decoration: none;
    color: white !important;
}

.header-text-link:visited {
    text-decoration: none;
    color:  white !important;
}

.header-link {
    text-decoration: none;
}

.header-link:hover {
    text-decoration: none;
}

.header-link:visited {
    text-decoration: none;
}

.dont-move-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index:1000;
}

/*Nav Bar CSS*/
.navbar {
    background-color: #002244;
    color: #FFFFFF;
}

.rtw-navbar {
    height: 80px;
    vertical-align: top;
    padding: 0 !important;
}

.empl-toolbar-arrow {
    float: right;
    height: 20px;
    width: 20px;
    padding-top: 14px;
    padding-left: 6px;
}

.sidenav-line {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.empl-toolbar-container {
    height: 70px;
}

.app-title-center {
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
}

.empl-button-active-color {
    background-color: #334E69 !important;
}

.nav-link {
    text-decoration: none;
    color: #FFFFFF;
}

.toggler {
    color: #FFFFFF;
    font-size: 24px;
}

.active {
    background-color: #69b3e7;
    border-radius: 0.3rem;
}

.nav-item {
    text-align: center !important;
}

.united-logo {
    padding-left: 24px;
}

.empl-button-master {
    height: 100%;
    padding: 12px;
}

body.modal-open {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 !important;
}

.loader {
    background-color: #e9e9e9;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: 9998;
}

    .loader.on {
        display: block !important;
    }

.loader-img {
    background: url(../images/loading.gif) no-repeat;
    background-position: center;
    background-size: 100%;
    width: 200px;
    max-width: 200px;
    height: 200px;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: calc(50% - 100px);
    z-index: 9999;
    margin-left: -100px;
    display: block;
}

.loading-txt-div {
    position: absolute;
    top: 50%;
    left: calc(50% - 38px);
    color: black;
    font-size: 16px;
}