.admin-toolbar {
    background-color: #DFE4E1;
    border: 1px solid #D4D4D0;
    margin-top: 10px;
    padding: 8px 4px;
}

.logged-in-text {
    padding: 6px;
    border-radius: 0.3rem;
    margin-left: 10px;
}

.impersonating-text {
    padding: 6px;
    border-radius: 0.3rem;
    margin-left: 10px;
}

