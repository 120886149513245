html, body {
    background-color: rgba(246,246,246,1) !important;
    font-family: "Open Sans";
}

hr {
    margin-bottom: 0px;
    margin-top: 0px;
}

.download-btn {
    float: right;
    padding-top: 2px !important;
}

.header-app-name {
    color: white;
    font-family: "Open Sans";
    font-size: 22px;
    letter-spacing: 2px;
}

.header-spacers {
    margin-top: 16px;
    margin-right: 10px;
    display: inline-block;
}

.pad-right-10 {
    padding-right: 10px;
}

.top-border-container {
    border-top: 1px solid #979797;
}

.adjust-container-color {
    background-color: white;
    padding-top: 10px;
}

.adjust-tertiary-card {
    margin: 10px; 
    width: 333px;
}

.adjust-carousel-pad {
    margin-bottom: 10px; 
    /* padding: 0px 10px 10px 10px; */
}

.adjust-padding-10 {
    padding: 10px;
}

.name-hover {
    padding: 10px 10px 10px 10px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
}

.long-name-adj {
    font-size: 12px !important;
}

.zoomed-photo {
    padding: 6px 6px 6px 6px;
    background-color: white;
    border-radius: 6px;
}

.bg-white-only {
    background-color: white !important;
}

.table>:not(:first-child) {
    border-top: 0px solid white !important;
}

.recent-label {
    color: #0C2340;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 15px;
}

.selected-favorite {
    color: #EDB72B;
}

.up-button-mg {
    margin-bottom: 10px;
}

a {
    color: #6244BB !important;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #6244BB;
    text-decoration: underline;
}

.table {
    background-color: #FFFFFF;
    margin: 10px auto;
    border-radius: 0.3rem;
    font-size: 13px;
    padding: 0rem;
}

th {
    border: none;
}

.main-card {
    width: 395px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.11);
    margin: auto;
}


.secondary-card {
    margin: auto;
    width: 348px !important;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.11);
    border: none !important;
    /*float: left; */
/*    width: calc(33.3% - 20px);
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
    float: left;
    margin: 10px;*/
}

.tertiary-card {
    margin: auto !important;
    width: 310px !important;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
    /* float: left; */
}

.landing-card {
    width: 100%;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.11);
    margin-bottom: 27px;
}

.bg-blue {
    background-color: #0C2340;
    color: white;
}

.center-pic {
    /*position: absolute;*/
    /*left: 44%;*/
    top: -60px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 110px; /* Need a specific value to work */
}

.empl-img-bigger {
    /* width: 120px; */
    float: left;
    height: 110px;
}

.image-cropper-bigger {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
}

.empl-img-large {
    /* width: 120px; */
    float: left;
}

.up-org-arrow {
    color: #999999;
}

.image-cropper-large {
    width: 75px;
    height: 75px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
}

.org-direct-report {
    margin-right: 0px !important;
}

.box-shadow-header {
    box-shadow: 0 2px 13px 0 rgba(0,0,0,0.1);
}

.initial-cropper-large {
    width: 75px;
    height: 75px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
    background-color: #dddddd;
    COLOR: #002244;
    padding-top: 12px;
    FONT-SIZE: 32px;
    FONT-FAMILY: 'OPEN SANS';
    font-weight: bold;
    letter-spacing: 0.15px;
    text-align: center;
}

.empl-img-medium {
    /* width: 120px; */
    float: left;
}

.image-cropper-medium {
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
}

.initials-cropper-medium {
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
    background-color: #dddddd;
    COLOR: #334e69;
    padding-top: 8px;
    FONT-SIZE: 36px;
    FONT-FAMILY: 'OPEN SANS';
    font-weight: bold;
    letter-spacing: 0.15px;
}

.image-cropper-small {
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
}

.initials-cropper-small {
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px 10px 10px 10px;
    background-color: #dddddd;
    COLOR: #334e69;
    padding-top: 6px;
    FONT-SIZE: 30px;
    FONT-FAMILY: 'OPEN SANS';
    font-weight: bold;
    letter-spacing: 0.15px;
}

.profile-pic-bigger {
    display: inline;
    margin: 0 auto;
    width: 110px;
    margin-left: -1px;
}

.profile-name {
    text-align: center;
    width: 100%;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10px;
}

.profile-subheader {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}

.profile-subheader-label {
    padding-top: 10px;
    opacity: 0.7;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}

.ua-card-body {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.ua-card-body-links {
    padding-left: 24px;
    padding-right: 24px;
    /*padding-top: 30px;
    padding-bottom: 30px;*/
}

.empl-toolbar-btn {
    background-color: transparent;
    color: inherit;
    border: none;
}

.empl-toolbar-btn:focus {
    border: none;
    outline: none;
}

.empl-toolbar-dropdown {
    color: #FFFFFF !important;
    position: absolute !important;
    min-width: 400px !important;
    left: auto;
    right: -13px;
    margin-top: -9px !important;
    border-top: 1px solid #CCC !important;
    background-color: #334E69 !important;
    box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25) !important;
    border-radius: 0% !important;
    padding: 12px !important;
}

.empl-toolbar-name {
    float: left;
    position: relative;
    padding-top: 10px;
    font-size: 13px;
    font-family: "Open Sans";
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;
    text-align: right;
}

.empl-toolbar-name-inactive {
    float: left;
    position: relative;
    padding-top: 20px;
    font-size: 13px;
    font-family: "Open Sans";
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;
    text-align: right;
}

.empl-toolbar-name-big {
    float: left;
    position: relative;
    padding-top: 14px;
    font-size: 16px;
    font-family: "Open Sans";
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;
}

.empl-toolbar-name-admin {
    float: left;
    position: relative;
    font-family: "Open Sans";
    padding-left: 8px;
    font-weight: bold;
}

.admin-toolbar-btn {
    float: left;
}

.admin-toolbar-txt {
    float: left;
    padding-right: 5px;
    width: 282px;
}

.width-44 {
    width: 44px;
}

.admin-toolbar-container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.empl-toolbar-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem;
    clear: both;
    font-weight: 400;
    color: #FFFFFF !important;
    text-align: right;
    float: right;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: "Open Sans";
    font-weight: bold;
    text-decoration: none;
}

.empl-toolbar-dropdown-item:hover {
    text-decoration: underline;
}

.rotate {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

.rotate.down {
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.empl-img-size-toolbar {
    height: 46px;
}

.empl-img-toolbar {
    width: 46px;
    float: left;
    height: 60px;
}

.image-cropper-toolbar {
    width: 46px;
    height: 46px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #0C2340;
}

.profile-pic-toolbar {
    display: inline;
    margin: 0 auto;
    width: 46px;
    margin-left: -1px;
}

.flippity {
    transform: rotate(-90deg);
}

.img-flippity {
    -moz-transition: transform .5s;
    -webkit-transition: transform .5s;
    transition: transform .5s;
}

.eval-icons {
    text-align: right;
    float: right;
    position: relative;
}

.date-field {
    color: #333333;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 13px;
}

.salutation {
    color: #333333;
    font-family: "Open Sans";
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 0px;
}

.grey-header-lbl {
    height: 18px;
    color: #777777;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.08px;
    line-height: 18px;
    margin-top: 15px !important;
}

.grey-header-txt {
    height: 18px;
    color: #777777;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.08px;
    line-height: 18px;
    margin-bottom: 15px !important;
}

.instructions {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    width: 100%;
    margin-bottom: 0px;
    padding: 26px 0px;
}

.wellness-check-btn, .wellness-check-btn:hover {
    height: 60px;
    width: 339px;
    border-radius: 6px;
    background-color: #6244BB;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 26px;
}

.clearance-btn, .clearance-btn:hover {
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-top: 26px;
    margin-bottom: 26px;
    background-color: transparent;
    box-sizing: border-box;
    height: 60px;
    width: 339px;
    border: 1px solid #6244BB;
    border-radius: 6px;
}

.contact-tracing-lbl {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 33px;
    width: 100%;
    margin-bottom: 0px;
    padding-top: 23px;
    font-weight: bold;
}

.contact-tracing {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    width: 100%;
    line-height: 22px;
    margin-bottom: 0px;
    padding-bottom: 26px;
}

.arrival-btn-instr {
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    border: none;
    background-color: transparent;
    padding: 36px 0;
}

.resource-btn, .resource-btn:hover, .resource-btn:visited {
    width: 100%;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-decoration: none;
}

.resource-btn-eta, .resource-btn-eta:hover, .resource-btn-eta:visited {
    width: 100%;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-decoration: none;
}

.resource-details-btn-eta, .resource-details-btn-eta:hover, .resource-details-btn-eta:visited {
    width: 100%;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding-top: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-decoration: none;
}

.resource-details-txt-eta {
    color: #333333;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 8px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    width: 90%;
}


.resource-details-btn, .resource-details-btn:hover, .resource-details-btn:visited {
    width: 100%;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding-top: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-decoration: none;
}

.resource-details-txt {
    color: #333333;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 8px;
    padding-bottom: 30px;
    margin-bottom: 0px;
    width: 90%;
}

.resource-btn-nolink {
    color: #000000;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 30px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
    text-decoration: none;
}

.resource-btn-disabled-eta, .resource-btn-disabled-eta:hover, .resource-btn-disabled-eta:visited {
    color: gray;
    pointer-events: none;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-decoration: none !important;
}

.banner-eta {
    border: 1px solid;
    border-left: 4px solid;
    border-color: #0C2340;
    margin-bottom: 1.5em;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "Open Sans";
}

.banner-icon-eta {
    padding-top: 0.5em;
}

.banner-text-eta {
    padding-left: 0.7em;
}

.banner-title-eta {
    font-weight: bold;
    font-size: 1.2em;
}

.banner-body-eta {
    font-size: 1em;
}

.united-together {
    color: #000000;
    /* font-family: ".SF NS Display"; */
    font-size: 18px;
    letter-spacing: 0.09px;
    line-height: 21px;
    text-align: center;
}

.united-safety {
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.15px;
}

.alert-div {
    padding-top: 72px;
    padding-bottom: 39px;
}

.alert-msg {
    color: #000000;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
}

.fail-msg {
    color: #000000;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 20px;
    margin-top: 20px;
}

.no-border {
    border: none !important;
}

.clearance-msg {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    margin-bottom: 14px;
}

.clearance-dt {
    color: #333333;
    font-family: "Open Sans";
    font-size: 36px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 32px;
}

.clearance-nm {
    color: #333333;
    margin-top: 24px;
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
}

.clearance-desk {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.clearance-disclaimer {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    margin-top: 23px;
}

.arrival-header {
    color: #000000;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
}

.arrival-txt {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
}

.no-side-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.side-padding-15px {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.modal-image-section {
    height: 120px;
}

.low-padding {
    padding: 6px !important;
}

.text-black {
    color: black;
}

.ua-text-right {
    text-align: right !important;
    float: right !important;
}

thead {
    font-size: 1.4em;
}

.no-top-border {
    border-top: none !important;
    white-space: nowrap;
}

td {
    vertical-align: middle;
    padding: 4px;
}

.margin-right-10px {
    margin-right: 10px;
}

.padding-bottom-25px {
    padding-bottom: 25px;
}

.search-icon {
    margin-right: 10px;
}

.search-icon:hover {
    cursor: pointer;
}

.search-box {
    display: block;
    border-radius: 0.3rem;
    border-color: #D4D4D0;
    border-style: solid;
    padding: 2px 4px;
    margin-top: 6px;
    font-size: 12px;
    width: 100%;
}

.search-box-long {
    width: 100%;
}

.wellness-container {
    width: 100%;
    /*height: 84px;
    padding-bottom: 10px; ''' messes up on mobile */ 
    font-size: 1.1em;
}

.wellness-img {
    padding-right: 10px;
    float:left;
    text-align:left;
}

.wellness-content {
    padding-bottom:10px;
    vertical-align: middle;
    display: table-cell;
    height: 84px;
    font-weight:600;
}

.trash-icon {
    color: #D50032;
}

.trash-icon:hover {
    cursor: pointer;
}

.add-user-icon {
    color: #002244;
}

.table-button {
    background-color: #D4D4D0;
    color: black;
}

.hide {
    display: none;
}

.employee-details-lg {
    width: 100%;
    padding-left: 10px;
    font-size: 100%;
}

.name-button {
    background: none !important;
    border: none !important;
    color: black !important;
    font-weight: bold !important;
    padding-left: 0px;
    padding-bottom: 0px;
}

.employee-img-list {
    max-width: 50px;
}

.org-btn {
    border: none;
    background-color: transparent;
    color: #572C5f;
    padding-bottom: 0px;
}

.width-100 {
    font-size: 1.4em;
    width: 100%;
}

.width-100px {
    width: 100px;
}

.width-50 {
    font-size: 1.4em;
    width: 49%;
}

.bg-gray {
    background-color: #f2f2f2;
}

.choice-btn {
    margin-bottom: 10px;
}

.link-btn {
    background-color: transparent;
    border: 0px;
}

.success-green-div {
    background-color: #208500;
    color: white;
    height: 140px;
    line-height: 140px;
    font-size: 120px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.success-div {
    color: white;
     height: 160px; 
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.no-vaccine-div {
    color: white;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.clearance-msg-2 {
    color: #333333;
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
}

.failure-red-div {
    background-color: white;
    color: #d50032;
    height: 140px;
    line-height: 140px;
    font-size: 120px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.notification-hdr {
    color: #F46335;
}

.notification-div {
    border: 1px solid #F46335;
    border-radius: 5px;
}

.notification-dismiss {
    color: #F46335;
    font-size: 1.4em;
}

.notification-close {
    padding: .25rem .5rem !important;
    opacity: 1;
}

.card-embed {
    padding: 0px;
}

.no-padding

.modal-logo {
    text-align: center;
    position:absolute;
    width: 100%;
}

.float-right {
    float: right;
}

.empl-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.empl-det {
    font-size: 100%;
    text-align: left;
}

.empl-img {
    width: 100px;
    float:left;
    height: 60px;
}

.empl-img-size {
    height: 132px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.empl-img-size-tbl {
    height: 46px;
    float: left;
    width: 40px;
}

.empl-img-tbl {
    width: 50px;
    float: left;
    height: 50px;
}

.empl-img-size-profile {
    height: 46px;
    float: left;
    width: 40px;
    padding-top: 6px;
}

.empl-img-profile {
    width: 50px;
    float: left;
    height: 50px;
}

.image-cropper-tbl {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.initial-cropper-tbl {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: #dddddd;
    COLOR: #334e69;
    padding-top: 9px;
    FONT-SIZE: 18px;
    FONT-FAMILY: 'OPEN SANS';
    font-weight: bold;
    letter-spacing: 0.15px;
}

.profile-pic-tbl {
    display: inline;
    margin: 0 auto;
    margin-bottom: -30%;
    margin-left: -3%;
    width: 42px;
}

.name-align {
    line-height: 80px;
    /* vertical-align: middle; */
    height: 24px;
    color: #000000;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
}

.image-cropper {
    width: 70px;
    height: 70px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.profile-pic {
    display: inline;
    margin: 0 auto;
    margin-bottom: -30%;
    margin-left: -3%;
    width: 73px;
}


.empl-img-lg {
    float: left;
    height: 120px;
}

.empl-img-size-lg {
    height: 144px;
}

.image-cropper-lg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.profile-pic-lg {
    display: inline;
    margin: 0 auto;
    margin-bottom: -30%;
}

.mgr-hr {
    margin-top: 4px;
}

.container-adj {
    /* max-width: 100% !important; */
}

.height-100 {
    height: 100%;
}

.fixed-table-layout {
    table-layout: fixed !important;
}

.prog-padding {
    padding-left: 24px;
    padding-right: 24px;
}

.pad-bot-10 {
    padding-bottom: 10px;
}

.pad-top-10 {
    padding-top: 10px;
}

.pad-top-20 {
    padding-top: 20px;
}

.left-border-table {
    border-left-width: 1px;
}

.right-border-table {
    border-right-width: 1px;
}

.collapsing {
    transition: .3s transform ease-in-out;
}

.question-row {
    padding-bottom: 6px;
    padding-top: 10px;
    border-bottom: 1px solid #888888;
}

.ua-active {
    border-radius: 0.3rem;
    background-color: white !important;
}

input[type=checkbox] {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    padding: 10px;
}

.one, .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten {
    position: absolute;
    margin-top: -7px;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 25px;
}

.prog-bar-ball-one, .prog-bar-ball-two, .prog-bar-ball-three, .prog-bar-ball-four, .prog-bar-ball-five, .prog-bar-ball-six, .prog-bar-ball-seven, .prog-bar-ball-eight, .prog-bar-ball-nine, .prog-bar-ball-ten {
    position: absolute;
    margin-top: -7px;
    z-index: 1;
    height: 20px;
    width: 20px;
    border-radius: 25px;
}

.progress-ovr {
    height: 4px;
}

.primary-color {
    background-color: #949494;
}

.progress-done {
    background-color: #002244;
}

.progress-active {
    background-color: white;
    border: 4px solid #002244;
    height: 26px;
    width: 26px;
    margin-top: -11px;
}

.no-color {
    background-color: inherit;
}

.probation-core4 {
    border-left: 1px solid;
    border-right: 1px solid;
    padding: 10px;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    padding-bottom: 54px;
}

.probation-core4-header {
    color: white;
    font-weight: bold;
    font-size: 150%;
}

.ua-progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #002244;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    /* box-shadow: inset 0 -1px 0 rgba(0,0,0,.15); */
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.question-card {
    height: 200px;
}

.question-card-center {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.question-disc-card {
    height: 80px;
    text-align: center;
}

.xtra-btn-pad {
    padding-left: 0px;
    padding-bottom: 12px;
}

.tbl-cal {
    table-layout: fixed;
    width: 70%;
    text-align: center;
    border: 0px solid white;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8em;
}

.tbl-dt {
    text-align: left;
    padding-left: 15%;
}

.th-cal {
    width: 10%;
    border: 0px solid white;
}

.td-cal {
    width: 10%;
    height: 44px;
    border: 0px solid white;
}

.td-cal-past {
    background-color: #e6e6e6;
    color: #838383;
}

.td-cal-today {
    color: black !important;
    font-weight: bold;
}

.div-today-overlay {
    border-radius: 50%;
    background-color: #f0c454;
    height: 80%;
    width: 80%;
    padding-top: 4px;
}

.td-cal-future {
    color: black;
    background-color: transparent;
}

.td-cal-work {
    background-color: #002244;
    color: #FFFFFF;
}

.reset-btn {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 68px;
    display: flex;
    border-radius: 50%;
    height: 68px;
}

.img-dull {
    opacity: 0.3;
}

.div-cal-img {
    float: left;
    text-align: left; 
    width: 60px;
}

.div-cal-content {
    float: left;
    text-align: left;
    width: 60%;
}

.div-arrive-content {
    float: left;
    text-align: left;
    width: 85%;
}


.span-platoon {
    font-size: 0.8em;
    font-weight: normal;
}

.div-cal-arrow {
    float: right;
    text-align: right;
    width: 20px;
}

.text-bold {
    font-weight: bold;
}

.tab-button-div {
    width: 100%;
    height: 60px;
}

.tab-button {
    border-bottom: 1px solid black;
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
    color: black;
   /* width: 25%; */
    border-radius: 0;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    float: left;
}

.tab-button-active {
    border-bottom: 4px solid black;
    font-weight: bold;
}


.chk-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    top: -3px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.chk-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.chk-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #666;
}

.wellness-btn {
    margin-right: 10px;
    width: 30%;
    padding: 10px;
    margin-bottom: 10px;
}

/* On mouse-over, add a grey background color */
.chk-container:hover input ~ .chk-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chk-container input:checked ~ .chk-checkmark {
    background-color: #6244BB;
}

/* Create the checkmark/indicator (hidden when not checked) */
.chk-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.chk-container input:checked ~ .chk-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.chk-container .chk-checkmark:after {
    left: 5px;
    top: 0px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ua-panel-header {
    background-color: #0C2340;
    color: white;
    font-family: 'Open Sans';
    font-size: 18px;
}

.submit-btn-width {
    width: 300px !important;
}

.pad-bot-4 {
    padding-bottom: 4px;
}

.orion-success-alert {
    box-sizing: border-box;
    height: 80px;
    /* max-width: 928px; */
    border: 1px solid #F4F4F4;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.25);
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding-top: 31px;
    z-index: 999;
}

.ua-footer {
    background-color: #0C2340;
    color: white;
    width: 100%;
    height: 100px;
    text-align: center;
}

.ua-footer-subtext {
    color: #FFFFFF;
    font-size: 12px;
}

.close-btn-padding-override {
    padding: 1.48rem 1.25rem !important;
}

.comment-header {
    font-weight: bold;
}

.comment-text {
    font-weight: normal;
}

.show-on-xs {
    display: none;
}

.show-on-sm {
    display: none;
}

.show-on-md {
    display: none;
}


/* date picker */
.calendar-button {
    background-color: #6244BB;
    padding: 6px 6px 6px 6px;
    margin-left: 10px;
    border-radius: 6px;
    border: 1px solid #6244BB;
}

/* 4 */
.calendar-icon {
    height: 24px;
    width: 24px;
    display: block;
    background-color: white;
    -webkit-mask-image: url("https://unitedemployeedesignsystem.zeroheight.com/api/asset/download.svg?asset_id=2039779&auth=NtsDDpk7BVwnclthnnULYw");
    mask-image: url("https://unitedemployeedesignsystem.zeroheight.com/api/asset/download.svg?asset_id=2039779&auth=NtsDDpk7BVwnclthnnULYw");
}

.header-currentuser-name {
    padding-right: 6px;
}

.next-carousel-btn {
    background-color: red;
}

.org-direct-bg {
    background-color: #F7F7F7;
    padding-top: 14px;
    margin-top: -2px;
}

.bg-direct-report-gray {
    background-color: #F7F7F7;
    height: calc(100vh - 60px);
}

.bg-direct-report-gray-adj {
    background-color: #F7F7F7;
}

.favorite-star {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
}

.direct-report-count {
    bottom: -20px;
    padding: 6px;
    font-size: 14px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.org-more-options {
    position: absolute;    
    right: -6px;
}

.fa-24px {
    font-size: 24px;
}

.fa-18px {
    font-size: 18px;
}

.org-more-options-secondary {
    position: absolute;
    right: -6px;
    top: 0px;
    /* padding: 10px; */
}

.org-more-options-tertiary {
    position: absolute;
    right: -6px;
    top: 0px;
    /* padding: 10px; */
}

.org-container-adj {
    max-width: 2282px !important;
}

.dd-badge-dark {
    display: inline-block;
    text-align: center;
    font-size: 13px;
    box-sizing: border-box;
    height: 28px;
    width: 55px;
    background-color: #495A70;
    border-radius: 14px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: white;
    padding-top: 3px;
}

.dd-badge-light {
    display: inline-block;
    text-align: center;
    font-size: 13px;
    box-sizing: border-box;
    height: 28px;
    width: 55px;
    background-color: white;
    border-radius: 14px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #495A70;
    border: 1px #495A70 solid;
    padding-top: 3px;
}

.round-badge {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    height: 20px;
    width: 26px;
    border: 1px solid #6244BB;
    border-radius: 10px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.fa-onepointfive {
    font-size: 1.5em;
}


.org-card-main {
}

.org-top-half-blue {
    border-radius: 6px 6px 0 0;
    background-color: rgba(12,35,64,0.8);
    color: white;
    width: 100%;
    height: 96px;
}

.org-top-half-blue:hover {
    background-color: #0C2340; 
}

.adjust-pointer-color {
    color: transparent;
}

.org-top-half-blue:hover .adjust-pointer-color {
    color: white;
    background-color: transparent;
}


.org-top-half-white {
    border-radius: 6px 6px 0 0;
    background-color: #F1F1F0;
    color: #333;
    width: 100%;
    height: 90px;
}

.dark-pointer-color {
    color: #F1F1F0;
}

.org-top-half-white:hover .dark-pointer-color {
    color: rgba(12,35,64,0.8);
}

.org-top-half-white-tertriary {
    border-radius: 6px 6px 0 0;
    background-color: white;
    color: #333;
    width: 100%;
    height: 80px;
}

.white-pointer-color {
    color: white;
}

.bravo-icon {
    width: 60px;
}

.no-underline {
    text-decoration: none;
}

.bravo-div {
    text-align: center;
    padding-bottom: 0px !important;
}

.org-top-half-white-tertriary:hover .white-pointer-color {
    color: rgba(12,35,64,0.8);
}

.org-rollup-link {
    color: black
}

.hide-rollup {
    display: none !important;
}

.org-bottom-half-white {
    border-radius: 0 0 6px 6px;
    background-color: #FFFFFF;
    width: 100%;
    text-align: left;
    padding: 8px 14px 14px 14px;
    height: 92px;
}

.org-details-modal {
    border-radius: 0 0 6px 6px;
    background-color: #FFFFFF;
    width: 100%;
    text-align: left;
    padding: 10px 20px 20px 20px;
}

.org-links-big {
    color: #333333 !important;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

.org-links-cc-big {
    color: #333333 !important;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
}

.org-links-cc-small {
    color: #333333 !important;
    font-family: "Open Sans";
    font-size: 10px;
    letter-spacing: 0;
    line-height: 20px;
}

.org-links-profile {
    color: #FFF !important;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 8px;
    padding-left: 14px;
    /* height: 28px; */
}

.org-button-link-main {
    border-radius: 4px;
    border: 1px solid  #FFF;
    background: #6244BB;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    color: #FFF !important;
    text-decoration: none;;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-right: 14px;
    margin-left: 14px;
    margin-top: 14px;
    margin-bottom: 7px;
}

.org-button-link-signout {
    border-radius: 4px;
    border: 1px solid  #FFF;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    color: #FFF !important;
    text-decoration: none;;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-right: 14px;
    margin-left: 14px;
    margin-bottom: 7px;
}

.org-links-value-profile {
    color: #FFF !important;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 8px;
    padding-left: 4px;
    height: 28px;
    font-weight: bold;
}

.org-links-big-modal {
    color: #666666 !important;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 8px;
}

.org-links-export-icon-modal {
    margin-top: -3px;
    margin-right: 2px;
}

.org-links-export-modal {
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 8px;
    text-decoration: none;
    font-weight: 600;
}

.org-links-big-modal-label {
    color: #666666 !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 8px;
}

.org-links-big-title {
    color: #333333 !important;
    font-family: "Open Sans";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
    padding-bottom: 6px;
    font-weight: bold;
}

.no-margin {
    margin: 0;
}

/* .org-non-links-big {
    color: #666666 !important;
    font-family: "Open Sans";
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
} */

.my-modal .modal-header {
    background-color: #002244;
    color: white !important;
  }

.org-empl-name-main {
    float: left;
    font-size: 20px;
    font-family: "Open Sans";
    margin-top: 8px;
    text-align: left;
    left: 100px;
    position: absolute;
}

.org-empl-name-secondary {
    font-size: 16px;
    font-family: "Open Sans";
    padding-top: 10px;
    text-align: left;
    color: #333;
    padding-right: 10px;
}

.org-empl-name-tertriary {
    font-size: 16px;
    font-family: "Open Sans";
    padding-top: 8px;
    text-align: left;
    color: #333;
    padding-right: 10px;
}

.org-empl-fullname-secondary {
    color: #0C2340 !important;
    font-weight: 800;
    text-decoration: none;
}

.org-empl-pos-main {
    font-size: 16px;
    font-weight: normal;
    font-family: "Open Sans";
}

.org-empl-pos-smaller-main {
    font-size: 14px;
    font-weight: normal;
    font-family: "Open Sans";
}

.org-icon {
    width: 14px;
    /* opacity: 0.5; */
    float: left;
    margin-right: 4px;
    margin-top: 6px;
}

.org-icon-2 {
    width: 14px;
    /* opacity: 0.5; */
    margin-right: 2px;
    margin-top: -2px;
    margin-left: 10px;
}

.org-icon-inherit {
    display: inherit !important;
}

.org-icon-modal {
    width: 14px;
    opacity: 0.5;
    float: left;
    margin-right: 4px;
    margin-top: 4px;
}

.org-icon-profile {
    width: 14px;
    float: left;
    margin-right: 16px;
    margin-top: 4px;
}

.org-icon-no-opac {
    width: 14px;
    float: left;
    margin-right: 4px;
    margin-top: 6px;
}

.org-icon-no-opac-modal {
    width: 14px;
    float: left;
    margin-right: 4px;
    margin-top: 6px;
}

.bg-gray-fix {
    background-color: white;
    /* margin: 12px;
    margin: 0px 12px 12px 12px; */
}

.org-direct-report-next {
    /*margin: 18px;*/
    margin-left: 18px;
    margin-right: 18px;
}

.org-empl-modal-separator {
    width: 100%;
    height: 50px;
    background-color: #f3f3f8;
    font-size: 1.15em;
    font-weight: bold;
    padding-left: 20px;
    padding-top: 10px;
}

.that-line-main {
    width: 100%;
}

.that-line-left {
    border-right: 1px solid #CCCCCC;
}

.that-line-right {
    border-left: 1px solid #CCCCCC;
}

.small-img {
    width: 100%;
}

.normal-font-weight {
    font-weight: normal;
}

.auto-pointer-events {
    pointer-events: auto !important;
}

.btn-search-bar {
    cursor: pointer;
    font-size: 28px;
    width: 36px;
    background-color: #002244;
    color: white;
    margin-top: -40px;
    vertical-align: middle;
}

.show-search-opt {
    display: none;
}

@media screen and (min-width: 1870px) and (max-width: 2280px) {
    .org-container-adj {
        max-width: 1872px !important;
    }
}

@media screen and (min-width: 1465px) and (max-width: 1870px) {
    .org-container-adj {
        max-width: 1467px !important;
    }
}

@media screen and (min-width: 1188px) and (max-width: 1465px) {
    .org-container-adj {
        max-width: 1190px !important;
    }
}

@media screen and (min-width: 778px) and (max-width: 1188px) {
    .org-container-adj {
        max-width: 780px !important;
    }
}

@media screen and (min-width: 598px) and (max-width: 778px) {
    .org-container-adj {
        max-width: 600px !important;
    }
}

@media screen and (max-width: 767px) {
    .show-search-opt {
        display: inline-block !important;
    }
}


/* MOBILE SPECIFIC STYLING */
@media screen and (max-width: 520px) {
    .org-links-export-modal {
        font-family: "Open Sans";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        padding-bottom: 8px;
        text-decoration: none;
    }

    .org-links-big-title {
        color: #333333 !important;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        padding-bottom: 6px;
        font-weight: bold;
    }

    .modal-mobile-override {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .header-left {
        text-align: left!important;
        padding-left: 30px !important;
    }

    .org-container-adj {
        max-width: 100% !important;
    }

    /* .main-card {
        width: 100%;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
        margin: auto;
    } */

    .empl-img-large {
        /* width: 120px; */
        float: left;
    }

    /* .image-cropper-large {
        width: 74px;
        height: 74px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 10px 10px 10px 10px;
    }
    
    .initial-cropper-large {
        width: 74px;
        height: 74px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 10px 10px 10px 10px;
        background-color: #dddddd;
        COLOR: #002244;
        padding-top: 10px;
        FONT-SIZE: 32px;
        FONT-FAMILY: 'OPEN SANS';
        font-weight: bold;
        letter-spacing: 0.15px;
        text-align: center;
    } */

    /* .org-empl-name-main {
        float: left;
        font-size: 16px;
        font-family: "Open Sans";
        margin-top: 8px;
        text-align: left;
        left: 80px;
        position: absolute;
        padding-right: 10px;
        padding-left: 10px;
    } */

    /* .org-empl-pos-main {
        font-size: 14px;
        font-weight: normal;
        font-family: "Open Sans";
    } */

    .small-img {
        width: 100%;
    }

    .org-top-half-blue {
        border-radius: 6px 6px 0 0;
        background-color: rgba(12,35,64,0.8);
        color: white;
        width: 100%;
        height: 96px;
    }

    .org-more-options {
        position: absolute;
        right: 0;
    }

/* 
    .secondary-card {
        margin: auto;
        width: 100% !important;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
        border: none !important;
    } */

    .empl-img-medium {
        /* width: 120px; */
        float: left;
        height: 80px;
    }

    /* .image-cropper-medium {
        width: 74px;
        height: 74px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 10px 10px 10px 10px;
    }

    .initials-cropper-medium {
        width: 74px;
        height: 74px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 10px 10px 10px 10px;
        background-color: #dddddd;
        COLOR: #334e69;
        padding-top: 9px;
        FONT-SIZE: 37px;
        FONT-FAMILY: 'OPEN SANS';
        font-weight: bold;
        letter-spacing: 0.15px;
    } */

    /* .org-empl-name-secondary {
        font-size: 16px;
        font-family: "Open Sans";
        padding-top: 10px;
        margin-left: 0px;
        text-align: left;
        color: #002244;
        padding-right: 10px;
    } */

    /* .org-empl-pos-smaller-main {
        font-size: 12px;
        font-weight: normal;
        font-family: "Open Sans";
    } */

    /* .tertiary-card {
        margin: auto !important;
        width: 100% !important;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
    } */

    .org-top-half-white {
        border-radius: 6px 6px 0 0;
        background-color: #F1F1F0;
        color: #333;
        width: 100%;
        height: 90px;
    }
}


/* Extra Small Screens */
@media screen and (max-width: 480px) {
    .show-on-xs {
        display: table-cell !important;
    }

    .show-on-sm {
        display: table-cell !important;
    }

    .show-on-md {
        display: table-cell !important;
    }


    .tbl-cal {
        table-layout: fixed;
        width: 90%;
        text-align: center;
        border: 0px solid white;
        margin-left: auto;
        margin-right: auto;
    }

    .tbl-dt {
        text-align: left;
        padding-left: 10%;
    }

    .td-cal {
        width: 10%;
        height: 40px;
        border: 0px solid white;
    }

    .wellness-btn {
        width: 100%;
    }

    .wellness-btn-confirm {
        width: 100%;
    }

    .wellness-btn-go-back {
        width: 100%;
    }

    .modal {
        padding-right: 0px !important;
    }

    .modal-full {
        min-width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .modal-full .modal-content {
        min-height: 98vh;
        border-radius: 0px;
    }

    .modal-buttons-bottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        padding-right: 30px;
    }

    .wellness-check-btn, .wellness-check-btn:hover {
        height: 60px;
        width: 100%;
        border-radius: 6px;
        background-color: #6244BB;
        color: #FFFFFF;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-bottom: 26px;
    }

    .clearance-btn, .clearance-btn:hover {
        color: #6244BB;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-top: 26px;
        margin-bottom: 26px;
        background-color: transparent;
        box-sizing: border-box;
        height: 60px;
        width: 100%;
        border: 1px solid #6244BB;
        border-radius: 6px;
    }

    .hidden-xs {
        display: none !important;
    }

    .hidden-sm {
        display: none !important;
    }

    .hidden-md {
        display: none !important;
    }

    .btn-adjust-small {
        width: 100%;
        margin-top: 10px;
    }

    .submit-btn-width {
        width: 100% !important;
    }

    .margin-top-small-screen {
        margin-top: 10px;
    }

    .calendar-button {
        background-color: #6244BB;
        padding: 4px 4px 4px 4px;
        margin-left: 10px;
        border-radius: 6px;
    }

    .ua-card-body {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}


/* MOBILE SPECIFIC STYLING */
/* specifically for org chart cards */
@media screen and (max-width: 420px) {
    .main-card {
        width: 100%;
        border-radius: 6px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 10px 1px rgb(0 0 0 / 11%);
        margin: auto;
    }

    .org-empl-name-main {
        float: left;
        font-size: 18px;
        font-family: "Open Sans";
        margin-top: 8px;
        text-align: left;
        left: 100px;
        position: absolute;
    }
}


/* Small Screens */
@media screen and (min-width: 481px) and (max-width: 767px) {
    .wellness-btn {
        width: 100%;
    }

    .wellness-btn-confirm {
        width: 100%;
    }

    .wellness-btn-go-back {
        width: 100%;
    }

    .modal {
        padding-right: 0px !important;
    }

    .modal-full {
        min-width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .modal-full .modal-content {
        min-height: 98vh;
        border-radius: 0px;
    }

    .modal-buttons-bottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        padding-right: 30px;
    }

    .hidden-sm {
        display: none !important;
    }

    .hidden-md {
        display: none !important;
    }

    .show-on-sm {
        display: table-cell !important;
    }

    .show-on-md {
        display: table-cell !important;
    }


    .btn-adjust-small {
        width: 100%;
        margin-top: 10px;
    }

    .submit-btn-width {
        width: 100% !important;
    }

    .margin-top-small-screen {
        margin-top: 10px;
    }

    .calendar-button {
        background-color: #6244BB;
        padding: 4px 4px 4px 4px;
        margin-left: 10px;
        border-radius: 6px;
    }
}

/* Medium Screens */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .show-on-md {
        display: table-cell !important;
    }

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }

    .btn-adjust-small {
        width: 300px !important;
    }

    .hidden-md {
        display: none !important;
    }
}

/* Large Screens */
@media screen and (min-width: 992px) and (max-width: 1199px) {
/*    .container-adj {
        max-width: 960px !important;
    }*/

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }

    .btn-adjust-small {
        width: 300px !important;
    }
}

/* Extra Large Screens */

@media screen and (min-width: 1200px) {
/*    .container-adj {
        max-width: 960px !important;
    }*/

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }

    .btn-adjust-small {
        width: 300px !important;
    }
}
