/* SWITCHS*/
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

    /* Hide default HTML checkbox */
    .switch input {
        display: none;
    }

/* The slider */
.sw-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d4d4d0;
    -webkit-transition: .4s;
    transition: .4s;
}

    .sw-slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

input:checked + .sw-slider {
    background-color: #6244BB;
}

input:focus + .sw-slider {
    box-shadow: 0 0 1px #006ba6;
}

input:checked + .sw-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.sw-slider.round {
    border-radius: 16px;
}

    .sw-slider.round:before {
        border-radius: 50%;
    }

/* END OF Slider*/



/* BEGIN ROUND SWITCH */
.chk-btn {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    margin: 0 5px 5px 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #000;
    text-align: center;
    background-color: #fff;
}

    .chk-btn label {
        cursor: pointer !important;
    }

    /* The round button switch */
    .chk-btn.round {
        width: 24px;
        border: 1px solid #d4d4d0;
        height: 24px;
        border-radius: 50%;
    }

        /* Hide default HTML checkbox */
        .chk-btn.round input {
            display: none;
        }

        .chk-btn.round :before {
        }

        .chk-btn.round input:checked + .chk-btn.round, .chk-btn.round input:checked + label {
            background-color: #006ba6;
            color: #fff;
        }

        .chk-btn.round input:focus + label, .chk-btn.round input:checked + label {
            width: 24px;
            top: 0;
            left: -1px;
            height: 23px;
            position: relative;
            padding-top: 2px;
        }

            .chk-btn.round input:checked + .chk-btn.round :before, .chk-btn.round input:checked + label:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }

        /* chk-btn inner */
        .chk-btn.round label {
            border-radius: 12px;
            font-weight: normal;
            font-size: 10px;
            margin-bottom: 0;
        }

            .chk-btn.round label:before {
                border-radius: 50%;
            }

    /* Round switch button end*/

    /* BEGIN CHECK BOX OVAL SWITCH */
    .chk-btn.oval {
        width: 50px;
        border: 1px solid #d4d4d0;
        height: 25px;
        border-radius: 12px;
    }
        /* Hide default HTML checkbox */
        .chk-btn.oval input {
            display: none;
        }

        .chk-btn.oval:before {
        }

        .chk-btn.oval + input:checked, .chk-btn.oval input:checked + label {
            background-color: #006ba6;
            color: #fff;
        }

        .chk-btn.oval input:focus + label, .chk-btn.oval input:checked + label {
            width: 50px;
            top: -1px;
            left: -1px;
            height: 25px;
            position: relative;
            padding-top: 3px;
        }

            .chk-btn.oval input:checked + .chk-btn.oval :before, .chk-btn.oval input:checked + label:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }

        /* Rounded sliders */
        .chk-btn.oval label {
            cursor: pointer;
            border-radius: 12px;
            top: 2px;
            font-weight: normal;
            left: 0;
            position: relative;
            margin-bottom: 0;
        }

            .chk-btn.oval label:before {
                border-radius: 50%;
            }

    /* Round switch button end*/


    /* BEGIN CHECK BOX OVAL-LONG SWITCH */
    .chk-btn.oval-long {
        width: 120px;
        border: 1px solid #d4d4d0;
        height: 25px;
        border-radius: 12px;
    }
        /* Hide default HTML checkbox */
        .chk-btn.oval-long input {
            display: none;
        }

        .chk-btn.oval-long:before {
        }

        .chk-btn.oval-long + input:checked, .chk-btn.oval-long input:checked + label {
            background-color: #006ba6;
            color: #fff;
        }

        .chk-btn.oval-long input:focus + label, .chk-btn.oval-long input:checked + label {
            width: 120px;
            top: -1px;
            left: -1px;
            height: 25px;
            position: relative;
            padding-top: 4px;
        }

            .chk-btn.oval-long input:checked + .chk-btn.oval-long :before, .chk-btn.oval-long input:checked + label:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }

        /* Rounded sliders */
        .chk-btn.oval-long label {
            cursor: pointer;
            border-radius: 12px;
            top: 3px;
            font-weight: normal;
            left: 0;
            position: relative;
            margin-bottom: 0;
        }

            .chk-btn.oval-long label:before {
                border-radius: 50%;
            }

/* Round switch button end*/


    /* BEGIN CHECK BOX square SWITCH */
    .chk-btn.square {
        width: 120px;
        border: 1px solid #d4d4d0;
        height: 25px;
        border-radius: 6px;
    }
        /* Hide default HTML checkbox */
        .chk-btn.square input {
            display: none;
        }

        .chk-btn.square:before {
        }

        .chk-btn.square + input:checked, .chk-btn.square input:checked + label {
            background-color: #006ba6;
            color: #fff;
        }

        .chk-btn.square input:focus + label, .chk-btn.square input:checked + label {
            width: 120px;
            left: -1px;
            height: 25px;
            position: relative;
        }

            .chk-btn.square input:checked + .chk-btn.square :before, .chk-btn.square input:checked + label:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }

        /* Rounded sliders */
        .chk-btn.square label {
            cursor: pointer;
            border-radius: 6px;
            font-weight: normal;
            left: 0;
            position: relative;
            margin-bottom: 0;
        }

.disabled-chk {
    background-color: #EEE;
    color: #CCC;
    cursor: not-allowed;
}

.chk-btn.square label:before {
    border-radius: 6px;
}

/* square switch button end*/


/* BEGIN FILTER OVAL-LONG SWITCH */
.filter-btn {
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    margin: 0 5px 5px 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #000;
    text-align: center;
    background-color: #fff;
}

.filter-btn {
    width: 165px;
    border: 1px solid #d4d4d0;
    height: auto;
    text-align: left;
}

.filter-btn-lbl {
    border-right: 1px solid #d4d4d0;
    padding-right: 3px;
    width: 30px;
    display: inline-block;
}

.filter-btn select:focus {
    outline: none;
}


.filter-btn > select {
    display: inline-block;
    border: none;
    box-shadow: none !important;
    width: 100%;
    font-size: 12px;
}
select.filter-btn-ddl {
    cursor: pointer;
}

select.filter-btn-ddl option {
    background-color: transparent !important;
}

.filter-btn > .filter-btn-lbl + select {
    display: inline-block;
    border: none;
    box-shadow: none !important;
    padding: 0;
    width: 115px;
    font-size: 12px;
    height: auto;
    vertical-align: baseline;
    margin-left: 2px;
}

/*.filter-btn.on {
    background-color: #006ba6;
    color: #fff;
}

    .filter-btn.on select, select.filter-btn-ddl.on {
        background-color: #006ba6;
        color: #fff;
    }*/



/* Round switch button end*/
