.chk-container-header {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

/* Hide the browser's default checkbox */
.chk-container-header input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* On mouse-over, add a grey background color */
.chk-container-header:hover input ~ .chk-checkmark {
    /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.chk-container-header input:checked ~ .chk-checkmark {
    background-color: #6244BB;
    border: 1px solid #6244BB;
}

/* Show the checkmark when checked */
.chk-container-header input:checked ~ .chk-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.chk-container-header .chk-checkmark:after {
    left: 5px;
    top: 0px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-header-background {
    background-color: #0C2340;
    color: white;
}

.modal-submit-button-width {
    width: 100px !important
}

.modal-button-disabled {
    border: 1px solid #693CC2 !important;
    border-radius: 4px;
    background-color: #FFFFFF !important;
    color: #6244BB !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 8px 8px 8px 8px;
    width: 100%;
}

.modal-button-enabled {
    border: 1px solid #693CC2 !important;
    border-radius: 4px;
    color: #FFFFFF !important;
    background-color: #6244BB !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    width: 100%;
}

.dropdown-arrow {    
    position: absolute;
    right: 36px;    
    margin-top: -7px;
}

.modal-button-enabled:disabled {
    border: 1px solid #666 !important;
    border-radius: 4px;
    color: #FFFFFF !important;
    background-color: #666 !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    width: 100%;
}

.btn-close-white {
    color: #FFFFFF !important;
    opacity: 1 !important;
}

.modal-footer-line {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.modal-footer-override {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 0px solid #dee2e6 !important;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }

.link-text {
    padding-right: 0.5em;
    color: var(--atlantic-amethyst);
    font-family: var(--open-sans-body-font-family);
  	font-size: var(--open-sans-body-font-size);
  	font-weight: var(--open-sans-h1-font-weight);  
  	text-align: var(--open-sans-body-text-align);
}

.link-icon {
    height: 16px;
    width: 10px;
    display: inline-block;
    background-color: var(--atlantic-amethyst);
    -webkit-mask-image: url('https://unitedemployeedesignsystem.zeroheight.com/api/asset/download.svg?asset_id=2472381&auth=NtsDDpk7BVwnclthnnULYw');
    mask-image: url('https://unitedemployeedesignsystem.zeroheight.com/api/asset/download.svg?asset_id=2472381&auth=NtsDDpk7BVwnclthnnULYw');
}

.header-left {
    text-align: left!important;
    padding-left:50px;
}

.padding-left-10px {
    padding-left: 10px;
}

.padding-top-10px {
    padding-top: 10px;
}

.div-name-search {
    float: left;
}

.div-advanced-search {
    float: left;
}

@media screen and (max-width: 767px) {
 

}

@media screen and (max-width: 907px) {
    .rtw-navbar {
        height: 60px;
        vertical-align: top;
        padding: 0 !important;
    }

    .empl-button-master {
        height: 100%;
        padding: 2px;
    }

    .united-logo {
        padding-left: 12px;
        width: 137px;
    }

    .empl-toolbar-dropdown {
        color: #FFFFFF !important;
        position: absolute !important;
        width: 100%;
        left: auto;
        right: -13px;
        margin-top: -18px !important;
        border-top: 1px solid #CCC !important;
        background-color: #334E69 !important;
        box-shadow: 0px 4px 4px 0px rgba(51, 51, 51, 0.25) !important;
        border-radius: 0% !important;
        padding: 12px;
    }

    .div-advanced-search {
        float: left;
        padding-top: 16px;
    }

    .div-name-search {
        float: left;
        width: 100%;
    }

    .advanced-search-span {
        margin-right: 12px;
        float: right;
        padding-left: 12px;
    }

    .search-bar-row-smaller {
        padding-left: 16px;
        padding-top: 16px;
        text-align: left;
    }

    .search-bar-row {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        min-height: 100px;
        text-align: left;
    }

    .main-wrap {
        padding-top: 162px;
        margin: 0;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        overflow-x: clip;
    }

    .search-bar-row-pills {
        min-height: 36px;
        text-align: left;
        margin-left: 16px;
        float: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .advanced-search-icon {
        width: 14px;
        height: 14px;
        display: inline-block;
        color: var(--atlantic-amethyst);
    }

    .search-bar-pill {
        color: #333;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding-top: 0px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 8px;
        margin-top: 8px;
        align-items: center;
        border-radius: 16px;
        border: 1px solid #024;
        background: #FFF;
    }

    .search-bar-remove-all-pill {
        color: #FFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding-top: 0px;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 8px;
        margin-top: 8px;
        align-items: center;
        border-radius: 16px;
        border: 1px solid var(--atlantic-amethyst);
        background: var(--atlantic-amethyst);
    }
}

@media screen and (max-width: 473px) {
    .advanced-search-icon {
        width: 18px;
        height: 18px;
        display: inline-block;
        color: var(--atlantic-amethyst);
    }

    .last-name-search-txt {
        width: 44%;
        margin-right: 24px;
        color: #666666;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid #666;
        padding-left: 24px;
    }

    .first-name-search-txt {
        margin-right: 16px;
        width: 44%;
        color: #666666;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid #666;
        padding-left: 24px;
    }

    .advanced-search-link {
        color: #6244bb;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-right: 8px;
    }

    .chk-container-header {
        position: relative;
        padding-left: 26px;
        margin-bottom: 0px !important;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }
}