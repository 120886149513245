.main-view {
    min-height: 300px;
    margin-bottom: 10px;
}

.grey-box {
    margin-top: 10px;
    background-color: #DFE4E1;
    border: 1px solid #D4D4D0;
    padding: 10px;
    border-radius: 3px;
}

.empl-panel {
    border: 1px solid #D4D4D0;
    background-color: #F6F6F6;
    margin-bottom: 20px;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.panel {
    background-color: #FFFFFF;
    border-radius: 4px;
}

.panel-survey {
    padding-left: 6px;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}

.panel-height {
    min-height: 84px;
}


.text-white {
    color: #FFF !important;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    line-height: 1em;
}

.fs-18 {
    font-size: 18px;
}

p {
    margin: 0 0 10px;
}

.fa-2x {
    font-size: 2em;
}

.text-right-not-xs {
    text-align: right;
}

.text-center-not-xs {
    text-align: center;
}

.panel-body {
    
}

.bg-white {
    background-color: #FFF !important;
    background-image: none;
    color: #000;
}

.title-vcenter {
    display: flex;
    margin: auto 0px;
    flex-direction: row;
    align-items: center;
}

.instruction-box {
    padding: 15px;
    border-radius: 3px;
}

.text-bold {
    font-weight: bold;
}

.text-underline {
    text-decoration: underline;
}




/* Small Screens */
@media screen and (max-width: 767px) {
    .panel-survey {
        padding-top: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
        margin-left: 15px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: table;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .panel {
        margin-bottom: 0px;
        border-radius: 4px;
    }
}

/* Medium Screens */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .panel-survey {
        padding-top: 12px;
        padding-right: 12px;
        padding-bottom: 12px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: table;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .panel {
        margin-bottom: 0px;
        border-radius: 4px;
    }
}

/* Large Screens */
@media screen and (min-width: 992px) and (max-width: 1199px) {

    .panel-height {
        min-height: 84px;
        height: 90%;
    }
}

/* Extra Large Screens */
@media screen and (min-width: 1200px) {

    .panel-height {
        min-height: 84px;
        height: 90%;
    }
}
