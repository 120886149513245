html,
body {
    max-width: 100%
}

.admin-aviate-navbar {
    height: 80px;
    vertical-align: top;
    padding-top: 0px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99998;
}

.sidenav-hide {
    width: 0px;
}

.admin-sidenav {
    top: 80px !important;
}

.admin-page-title {
    position: absolute;
    width: 100%;
    font-size: 24px;
    letter-spacing: 0;
    z-index: 0;
}

.admin-hamburger {
    font-size: 40px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.admin-container-new {
    padding-top: 120px;
}

.admin-container {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
}

.admin-section-header {
    color: #0C2340;
    font-family: "Open Sans";
    font-size: 33px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 30px;
    padding-left: 15px;
}

.admin-card-title {
    color: #333333;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 20px;
}

.admin-line {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.admin-side-chart-obj {
    height: 54px;
    margin-right: 2px;
    float: left;
    box-sizing: border-box;
}

.admin-side-chart-txt {
    color: #363B4E;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 2px;
    float: left;
    box-sizing: border-box;
}

.no-border-btn {
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
}

.copyright {
    color: white;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.copyright-admin {
    color: white;
    text-align: center;
    position: absolute;
    top: 88vh;
    width: 100%;
}

.admin-dropdown-menu {
    padding-right: 10px;
    padding-left: 10px;
    min-width: 300px;
    right: 100px !important;
    margin-right: 56px !important;
    box-shadow: -2px 0px 6px 0px #CCCCCC;
}

.partipant-sort-btn {
    border: none;
    background-color: transparent;
    color: white;
}

.refresh-link-btn {
    border: none;
    background-color: transparent;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
}

.selected-row {
    background-color: #666666;
    color: white;
}

/* for pie chart legend */
.canvas-con {
    display: flex;
    align-items: top;
    justify-content: center;
    min-height: 200px;
    position: relative;
}

.canvas-con-inner {
    height: 100%;
}

.canvas-con-inner, .legend-con {
    display: inline-block;
}

.chart-legend {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    display: flex;
    border-radius: 50%;
    float: left;
}

.chart-legend-label-text {
    float: left;
    font-size: 14px;
}

.chart-legend-item {
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
}
/* end pie chart legend */

.admin-filter-btn {
    box-sizing: border-box;
    height: 33px;
    border: 1px solid #6244BB;
    border-radius: 24px;
    background-color: #FFFFFF;
    color: #6244BB;
    font-size: 14px;
    font-weight: 300;
    padding-right: 15px;
    padding-left: 15px;
}

.first-name-search-icon {
    position: absolute;
    margin-top: 4px;
    margin-left: 0px;
    width: 18px;
}

.last-name-search-icon {
    position: absolute;
    margin-top: 4px;
    margin-left: 0px;
    width: 18px;
}

.first-name-search-txt {
    margin-right: 16px;
    width: 200px;
    color: #666666;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #666;
    padding-left: 24px;
}

.last-name-search-txt {
    width: 200px;
    margin-right: 24px;
    color: #666666;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #666;
    padding-left: 24px;
}

.search-bar-row {
    padding-left: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    height: 96px;
    text-align: left;
}

.search-bar-row-smaller {
    padding-left: 32px;
    padding-top: 32px;
    text-align: left;
}

.advanced-search-span {
    margin-right: 12px;
}

.advanced-search-link {
    color: #6244bb;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-right: 8px;
}

.advanced-search-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    color: var(--atlantic-amethyst);
}

.search-bar-row-pills {
    min-height: 48px;
    text-align: left;
    margin-left: 32px;
    float: left;
    width: 100%;
}

.search-bar-remove-all-pill {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--atlantic-amethyst);
    background: var(--atlantic-amethyst);
}


.search-bar-pill {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    padding-top: 0px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
    margin-top: 8px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #024;
    background: #FFF;
}

.admin-search-bar {
    width: calc(50% - 17px);
    color: #666666;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    background-color: transparent;
    border: 0px solid transparent;
    outline: none;
    border: none;
}

.admin-border-bottom-div {
    border-bottom: 1px solid #666666;
    width: 350px;
    padding-bottom: 4px;
}

.admin-table-hdr {
    /* height: 80px; */
    border-radius: 4px 4px 0 0;
    background-color: #0C2340;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

.admin-table {
    margin-left: 15px;
    margin-right: 15px;
}

.orion-table-hdr {
    background-color: #024;
    color: white;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 56px;
}


.orion-table-hdr-td {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    text-align: left;
    vertical-align: middle !important;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}

.font-14 {
    font-size: 14px !important;
    font-family: "Open Sans";
}

.orion-table-tr {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 56px;
}

.orion-table-td {
    color: #333;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 16px 12px;
    align-items: flex-start;
    background-color: #FFF;
    vertical-align: middle !important;
    text-align: left;
}

.orion-table-photo-td {
    color: #333;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-top: 4px !important;
    padding-left: 10px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    align-items: flex-start;
    background-color: #FFF;
    vertical-align: middle !important;
    text-align: center;
    width: 60px;
    border-left-width: 1px;
}

.orion-table-arrow-td {
    color: #333;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    align-items: flex-start;
    background-color: #FFF;
    vertical-align: middle !important;
    text-align: center;
    border-right-width: 1px;
}

.admin-data {
    vertical-align: middle !important;
    color: #000000;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding-bottom: 0px !important;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.arrow-column {
    padding-right: 15px !important;
    text-align: right;
    width: 60px;
}

.photo-column {
    width: 60px;
}

.table td, .table th, .table tr {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}

.btn-export-pdf {
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    border: none;
    background-color: transparent;
}

/* STATUS BAR */

.status-row {
    margin: 0;
    align-items: center;
}

.status-progress-labels {
    display: inline-block;
    font-family: var(--open-sans-body-font-family);
}

.status-progress-title {
    font-size: var(--open-sans-h3-font-size);
    font-weight: bold;
    color: var(--_333333);
}

.status-progress-body {
    font-size: var(--open-sans-body-font-size);
    font-weight: normal;
    color: var(--rhapsody-blue);
}

/* 3 */
.status-progress {
    width: 25%;
    height: 100%;
    background-color: transparent;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.status-progressbar-back {
    stroke: #cccccc;
    stroke-width: 6;
    fill: none;
}

.status-progressbar-line {
    stroke: #0C2340;
    stroke-width: 12;
    fill: none;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50px 50px;
    stroke-dasharray: 304.777px, 304.777px;
    stroke-dashoffset: 304.777px;
    transition: stroke-dashoffset 1s ease;
}

.status-progressbar-marker {
    fill: white;
    stroke: #00B2A9;
    stroke-width: 2;
    transform-origin: 50px 50px;
    transform: rotate(0deg);
    transition: transform 1s ease;
}

/* 4 */
.status-progressbar-center {
    position: absolute;
    top: 18%;
    left: 5.5%;
}

.status-progress-icon {
    height: 30px;
    width: 30px;
    margin-left: 6px;
    margin-top: 1px;
}

.status-admin-progress-icon {
    height: 30px;
    width: 30px;
    margin-left: 7px;
    margin-top: 1px;
}
/* END STATUS BAR */

.admin-flight-rating {
    box-sizing: border-box;
    height: 29px;
    width: 44px;
    border: 1px solid #727272;
    border-radius: 12px;
    color: #000000;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 4px;
}

/* Hidden Divs */
.close-participant-btn {
    border: 0px solid transparent;
    border: none;
    background-color: transparent;
    font-size: 48px;
    color: #666666;
    margin-top: -20px;
}

.back-participant-btn {
    border: 0px solid transparent;
    border: none;
    background-color: transparent;
    font-size: 24px;
    color: #666666;
}

.participant-container {
    position: absolute;
    top: 80px;
    right: 3%;
    width: 50%;
    display: none;
    min-height: calc(100% - 80px);
    overflow: hidden;
    background-color: white;
    padding: 15px 15px 15px 15px;
    box-shadow: -2px 0 4px 0 #CCCCCC;
    z-index: 999;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.selected {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
    /* display: block; */
}

.dismiss {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    /* display: none; */
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

/* end Hidden Divs */

.admin-flag-alert {
    box-sizing: border-box;
    border: 2px solid rgba(244,99,53,0.25);
    border-radius: 6px;
    background-color: #FEF7F5;
    color: #333333;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 16px 16px 16px 16px;
    margin-bottom: 16px;
}

.no-bold {
    font-weight: normal;
}

.admin-success-alert {
    box-sizing: border-box;
    border: 1px solid #208500;
    border-radius: 6px;
    background-color: rgba(32,133,0,0.1);
    color: #333333;
    font-family: "Open Sans";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
    padding: 16px 16px 16px 16px;
    margin-bottom: 16px;
}

.admin-participant-header {
    color: #0C2340;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}

.admin-participant-subheader {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}

.admin-edit-icon {
    background-color: transparent;
    border: none;
    border: 0px solid transparent;
}

.admin-participant-label-left {
    width: 30%;
    text-align: right;
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.admin-participant-value-left {
    padding-left: 20px;
    width: 30%;
    text-align: left;
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.admin-participant-label-right {
    width: 20%;
    text-align: right;
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.admin-participant-value-right {
    padding-left: 20px;
    width: 20%;
    text-align: left;
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    padding-bottom: 3px;
    padding-top: 3px;
}

.admin-participant-value-label {
    width: 40%;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
}

.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.admin-participant-line {
    margin-top: 10px;
    margin-bottom: 10px;
}

.big-pic {
    position: absolute;
    left: 80px;
}

.empl-img-big {
    width: 84px;
    float: left;
    height: 100px;
}

.image-cropper-big {
    width: 84px;
    height: 84px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 3px solid #0C2340;
}

.profile-pic-big {
    display: inline;
    margin: 0 auto;
    width: 84px;
    margin-left: -1px;
}

.admin-notes {
    background-color: rgba(234, 234, 243, 0.6);
    color: #333333;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    padding: 10px 10px 10px 10px;
}

.admin-notes-alt {
    background-color: rgba(234, 234, 243, 0.2);
    color: #333333;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    padding: 10px 10px 10px 10px;
}

.admin-notes-name {
    color: #666666;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 17px;
    width: 50%;
    float: left;
}

.admin-notes-line {
    margin-bottom: 4px;
    margin-top: 4px;
}

.admin-notes-btn {
    border: none;
    background-color: transparent;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.readmore .moreText {
    display: none;
}

.readmore a.more {
    display: block;
}

.more {
    color: #6244BB;
    text-decoration: none;
    position: relative;
    text-align: right;
    float: right;
    top: 12px;
    font-size: 18px;
    font-weight: 100;
}

/* STEPPER */
/* 2 */
#canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* 3 */
.stepper {
    padding-top: 0.5em;
    margin-left: auto;
    /* margin-right: 20px; */
}

.stepper-row {
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans";
    color: #0C2340;
}

/* 4 */
.step-title {
    font-size: 12px;
}

.step-title-other {
    font-size: 12px;
    margin-top: 12px;
}

.step-indicator {
    font-weight: normal;
}

/* 5 */
.step-icon.complete {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: block;
    background-color: #0C2340;
}

.step-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: block;
    background-color: #949494;
}

    .step-icon.current {
        background-color: white;
        border: 5px solid #0C2340;
        height: 26px;
        width: 26px;
        z-index: 1;
    }

.stepper-line {
    width: 100%;
    height: 2px;
    position: absolute;
    padding-right: 30px;
    /* z-index: -1; */
}

.stepper-line-complete {
    background-color: #0C2340;
    height: 4px;
    float: left;
}

.stepper-line-incomplete {
    background-color: #949494;
    height: 2px;
    float: left;
}

.stepper-bar {
    width: calc(100% - 48px);
    height: 12px;
    position: absolute;
    /* padding-right: 48px; */
    z-index: -1;
}

.stepper-bar-complete {
    background-color: #41748D;
    height: 12px;
    float: left;
}

.stepper-bar-incomplete {
    background-color: #D8D8D8;
    height: 12px;
    float: left;
}

.stepper-line-break {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.admin-push-phase-disabled {
    border: 1px solid #693CC2;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 8px 8px 8px 8px;
    width: 100%;
}

.admin-push-phase-enabled {
    border: 1px solid #693CC2;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 8px 8px 8px 8px;
    width: 100%;
}

.sortable-field {
    cursor: pointer;
}

.select-phase-div {
    border: 1px solid rgba(0,0,0,.1);
    background-color: white;
    border-radius: 6px;
    padding: 15px 15px 15px 15px;
    margin: 7.5px 7.5px 7.5px 7.5px;
    width: calc(50% - 15px);
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 11%);
}

.handle {
    font-size: 20px;
    color: transparent;
}

.config-edit {
    color: transparent;
    font-size: 20px;
}

.config-edit-dark {
    cursor: pointer;
    color: #6244BB;
    font-weight: bold;
}

.config-cancel-btn {
    border: none;
    background-color: transparent;
    color: red;
    font-weight: bold;
    float: left;
    font-size: 30px;
    padding-top: 4px
}

.pointer {
    cursor: pointer;
}

.config-txt {
    width: 80%;
    float: left;
}

.no-top-bottom-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.config-save-btn {
    border: none;
    background-color: transparent;
    color: green;
    font-size: 30px;
    float: left;
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.notif-dismiss-btn {
    border: none;
    background-color: transparent;
    color: black;
    font-weight: bold;
    float: left;
    font-size: 20px;
}

.handle-dark {
    color: #6244BB;
    font-weight: bold;
}

.tbody:hover {
    background-color: #FAFAFF;
}

.placeholdercss {
    border-style: dashed;
    background-color: rgba(182,184,220,0.23);
    color: rgba(182,184,220,0.23);
    height: auto;
}

.modal-button-active {
    border: 1px solid #693CC2;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 8px 8px 8px 8px;
    width: 40%;
}

.modal-button-inactive {
    border: 1px solid #693CC2;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 8px 8px 8px 8px;
    width: 40%;
}

.badge-alert {
    background-color: #6244BB;
    color: white;
    padding: .5em .5em .5em .5em;
    border-radius: 50%;
    margin-bottom: -2px;
}

.modal {
    padding-right: 0px !important;
}

/* Extra Small Screens */
@media screen and (max-width: 480px) {
    .admin-border-bottom-div {
        border-bottom: 1px solid #666666;
        width: 100%;
        padding-bottom: 4px;
        float: right;
    }

    .tbl-cal {
        table-layout: fixed;
        width: 90%;
        text-align: center;
        border: 0px solid white;
        margin-left: auto;
        margin-right: auto;
    }

    .tbl-dt {
        text-align: left;
        padding-left: 10%;
    }

    .td-cal {
        width: 10%;
        height: 40px;
        border: 0px solid white;
    }

    .wellness-btn {
        width: 100%;
    }

    .wellness-btn-confirm {
        width: 100%;
    }

    .wellness-btn-go-back {
        width: 100%;
    }

    .modal {
        padding-right: 0px !important;
    }

    .modal-full {
        min-width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .modal-full .modal-content {
        min-height: 98vh;
        min-height: calc(var(--vh, 1vh) * 98);
        border-radius: 0px;
    }

    .modal-content {
        min-height: 98vh;
        min-height: calc(var(--vh, 1vh) * 98);
        border-radius: 0px;
    }

    .modal-buttons-bottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        padding-right: 30px;
    }

    .wellness-check-btn, .wellness-check-btn:hover {
        height: 60px;
        width: 100%;
        border-radius: 6px;
        background-color: #6244BB;
        color: #FFFFFF;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-bottom: 26px;
    }

    .clearance-btn, .clearance-btn:hover {
        color: #6244BB;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-top: 26px;
        margin-bottom: 26px;
        background-color: transparent;
        box-sizing: border-box;
        height: 60px;
        width: 100%;
        border: 1px solid #6244BB;
        border-radius: 6px;
    }

    .logbook-logflight-btn {
        border-radius: 6px;
        background-color: #6244BB;
        height: 60px;
        width: 100%;
        color: #FFFFFF;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
    }

    .participant-container {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 100%;
        display: none;
        height: calc(100% - 80px);
        overflow: hidden;
        background-color: white;
        padding: 15px 15px 15px 15px;
        box-shadow: -2px 0 4px 0 #cccccc;
        z-index: 999;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    .admin-dropdown-menu {
        padding-right: 10px;
        padding-left: 10px;
        min-width: 300px;
        right: 100px !important;
        margin-right: 0px !important;
        box-shadow: -2px 0px 6px 0px #CCCCCC;
    }

    .chk-btn.square {
        width: 48%;
        border: 1px solid #d4d4d0;
        height: 25px;
        border-radius: 6px;
    }

    .chk-btn.square input:focus + label, .chk-btn.square input:checked + label {
        width: 100%;
        left: 0px;
        height: 25px;
        position: relative;
    }
}

/* Small Screens */
@media screen and (min-width: 481px) and (max-width: 767px) {
    .wellness-btn {
        width: 100%;
    }

    .wellness-btn-confirm {
        width: 100%;
    }

    .wellness-btn-go-back {
        width: 100%;
    }

    .modal {
        padding-right: 0px !important;
    }

    .modal-full {
        min-width: 100%;
        margin: 0px 0px 0px 0px;
    }

    .modal-full .modal-content {
        min-height: 98vh;
        min-height: calc(var(--vh, 1vh) * 98);
        border-radius: 0px;
    }

    .modal-content {
        min-height: 98vh;
        min-height: calc(var(--vh, 1vh) * 98);
        border-radius: 0px;
    }

    .modal-buttons-bottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        padding-right: 30px;
    }

    .participant-container {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 100%;
        display: none;
        height: calc(100% - 80px);
        overflow: hidden;
        background-color: white;
        padding: 15px 15px 15px 15px;
        box-shadow: -2px 0 4px 0 #cccccc;
        z-index: 999;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    .admin-dropdown-menu {
        padding-right: 10px;
        padding-left: 10px;
        min-width: 300px;
        right: 100px !important;
        margin-right: 0px !important;
        box-shadow: -2px 0px 6px 0px #CCCCCC;
    }
}

/* Medium Screens */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .modal-button-adjust-width {
        width: 250px !important;
    }

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }

    .modal-full {
        min-width: 80%;
    }

    .participant-container {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 100%;
        display: none;
        height: calc(100% - 80px);
        overflow: hidden;
        background-color: white;
        padding: 15px 15px 15px 15px;
        box-shadow: -2px 0 4px 0 #cccccc;
        z-index: 999;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    .admin-dropdown-menu {
        padding-right: 10px;
        padding-left: 10px;
        min-width: 300px;
        right: 100px !important;
        margin-right: 0px !important;
        box-shadow: -2px 0px 6px 0px #CCCCCC;
    }
}

/* Large Screens */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .modal-button-adjust-width {
        width: 300px !important;
    }

    .participant-container {
        position: absolute;
        top: 80px;
        right: 0px;
        width: 100%;
        display: none;
        height: calc(100% - 80px);
        overflow: hidden;
        background-color: white;
        padding: 15px 15px 15px 15px;
        box-shadow: -2px 0 4px 0 #cccccc;
        z-index: 999;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }

    .modal-full {
        min-width: 900px;
    }

    .admin-dropdown-menu {
        padding-right: 10px;
        padding-left: 10px;
        min-width: 300px;
        right: 100px !important;
        margin-right: 0px !important;
        box-shadow: -2px 0px 6px 0px #CCCCCC;
    }

/*    .container-adj {
        max-width: 960px !important;
    }*/

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }
}

/* Extra Large Screens */

@media screen and (min-width: 1200px) {
    .modal-button-adjust-width {
        width: 300px !important;
    }

/*    .container-adj {
        max-width: 1200px !important;
    }*/

    .modal-full {
        min-width: 1040px;
    }

    .instructions {
        color: #333333;
        font-family: "Open Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding: 26px 0px;
    }
}
