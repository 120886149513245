/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}

/* 2 */
.radio-item {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    margin: 0.5em;
    cursor: pointer;
    font-family: "Open Sans";
    color: #333333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* 3 */
    .radio-item input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

/* 4 */
.radio-button {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 2px solid #666666;
    background-color: white;
    margin-right: 0.5em;
}

    .radio-button.disabled {
        border-color: #949494;
        background-color: #949494;
    }

.radio-label.disabled {
    color: #666666;
}

/* 5 */
.radio-item .radio-button::after {
    content: "";
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #6244BB;
    transform: translate(12%, 12%);
}

/* 6 */
.radio-item input:checked ~ .radio-button {
    border: 2px solid #6244BB;
}

    .radio-item input:checked ~ .radio-button::after {
        display: block;
    }

/* 7 */
.radio-item:hover input ~ .radio-button {
    background-color: none;
}

.dropdown-menu-ua {
    width: 400PX;
    BACKGROUND-COLOR: WHITE !important;
    PADDING-LEFT: 10PX;
    PADDING-RIGHT: 10PX;
}