.center-row-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.empty-employee-card {
    margin: 0px 10px;
}

.employee-card {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
    height: 160px;
    border: 1px solid #d4d4d0;
    background-color: #f6f6f6;
}

.employee-name {
    font-size: 1em;
    font-weight: bold;
}

.employee-title {
    font-size: 11px;
}

.employee-details {
    width: 65%;
    padding-left: 10px;
    font-size: 100%;
}

.employee-img {
    max-width: 50px;
}

.control-box {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border: 1px solid #d4d4d0;
    background-color: #f6f6f6;
}

.control {
    margin: 4px 4px;
    font-size: 12px;
    position: relative;
    /*background-color: #B6B8DC;*/
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.375rem 0.375rem;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.disable-button {
    color: #6244BB;
    font-weight:bold;
    background-color: white;
    border: 1px solid #6244BB;
}

.div-rtw-content {
    float: left;
    text-align: left;
    width: 85%;
}

.photo-margins{
    margin-left:20px;
}

.settings-white-space{
    height:95vh;
}

.settings-button {
    width: 60%;
    height: 60px;
    padding: 17px 0;
}

.name-align-settings {
    vertical-align: middle;
}

.margin-top-20{
    margin-top:20px;
}

.margin-bot-20{
    margin-bottom:20px;
}

.margin-bot-30{
    margin-bottom:30px;
}

.font-bold{
    font-weight:600!important;
}

.wellness-text {
    color: #333333;
    font-family: "Open Sans";
    font-size: 16px;
    /* font-weight: bold;    */
}

.wellness-btn-accept, .wellness-btn-accept:hover {
    height: 60px;
    width: 382px;
    color: #FFFFFF;
    background-color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-right: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.wellness-btn-decline, .wellness-btn-decline:hover {
    height: 60px;
    width: 382px;
    color: #6244BB;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    border: 1px solid #6244BB;
    margin-right: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.wellness-btn-confirm, .wellness-btn-confirm:hover {
     color: white;
    background-color: #6244bb;
    border: 1px solid #6244bb;
}

.wellness-btn-go-back, .wellness-btn-go-back:hover {
    color: #6244bb;
    background-color: white;
    border: 1px solid #6244bb;
}

.slider-size{
    width:60px;
    height:36px;
}

.fs-13{
    font-size:13px;
}

.fs-18{
    font-size:18px;
}

.fs-24 {
    font-size: 24px;
}

.text-white{
    color:white
}

.wd-30 {
    width: 30%;
}

.rb-color-1 {
    background-color: #B6B8DC; /* LIGHT */
}

.rb-color-1:hover .checkmark::before {
    color: #6244bb; /* DARK */
}

.rb-color-1-selected {
    background-color: #6244bb; /* DARK */
}

.rb-color-2 {
    background-color: #bee6f7; /* LIGHT */
}

.rb-color-2:hover .checkmark::before {
    color: #009cdd; /* DARK */
}

.rb-color-2-selected {
    background-color: #009cdd; /* DARK */
}

.rb-color-3 {
    background-color: #dde2e3; /* LIGHT */
}

.rb-color-3:hover .checkmark::before {
    color: #7d888e; /* DARK */
}

.rb-color-3-selected {
    background-color: #7d888e; /* DARK */
}

.rb-color-4 {
    background-color: #cfdce4; /* LIGHT */
}

.rb-color-4:hover .checkmark::before {
    color: #41758e; /* DARK */
}

.rb-color-4-selected {
    background-color: #41758e; /* DARK */
}

.rb-color-5 {
    background-color: #ccf0ee; /* LIGHT */
}

.rb-color-5:hover .checkmark::before {
    color: #02b2aa; /* DARK */
}

.rb-color-5-selected {
    background-color: #02b2aa; /* DARK */
}

.rb-color-6 {
    background-color: #f3ede0; /* LIGHT */
}

.rb-color-6:hover .checkmark::before {
    color: #cdb887; /* DARK */
}

.rb-color-6-selected {
    background-color: #cdb887; /* DARK */
}

.rb-color-7 {
    background-color: #dddbd8; /* LIGHT */
}

.rb-color-7:hover .checkmark::before {
    color: #786e65; /* DARK */
}

.rb-color-7-selected {
    background-color: #786e65; /* DARK */
}

.control input[type='radio'] {
    opacity: 0;
}

.checkmark {
    height: 10px;
    width: 10px;
    top: 0;
    left: 0;
    background-color: #B6B8DC;
    cursor: pointer;
    font-family: FontAwesome;
    font-size: 1.5em;
}

.control:hover .checkmark::before {
    top: -12px;
    right: -4px;
    float: right;
    position: absolute;
    display: block;
    content: "\f058";
    border: 1px solid transparent;
    /*color: #6244bb;*/
    font-size: 1.2em;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.control-selected {
    margin: 4px 4px;
    font-size: 12px;
    position: relative;
    /*background-color: #6244bb;*/
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.375rem 0.375rem;
    cursor: pointer;
    color: white;
}

.control .checkmark.selected:after {
    display: block;
    background-color: transparent;
    color: #FFF;
    font-size: 1.2em;
    border-radius: 0.50rem;
    content: "\f058";
}

.control .checkmark:after {
    top: -12px;
    right: -4px;
    float: right;
    position: absolute;
    font-size: 1.2em;
    content: "\f058";
    font-family: FontAwesome;
}

.info {
    margin-left: 10px;
}

.divider {
    width: 90%;
    border-bottom: 1px solid #D4D4D0;
    margin: 0px auto 5px;
}

.comment-box {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #d4d4d0;
    background-color: #f6f6f6;
}

.comment {
    margin: 5px 10px;
}


.hide {
    display: none;
    margin: 0px;
}

.save-button {
    width: 100px;
    display: block;
    margin: 10px auto;
}

.save-button-div {
    padding-top: 10px;
    float: right;
  
}


@media screen and (max-width: 480px) {

    .wellness-btn-accept, .wellness-btn-accept:hover {
        height: 60px;
        width: 100%;
        color: #FFFFFF;
        background-color: #6244BB;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-right: 10px;
        padding: 10px;
        margin-bottom: 10px;
    }

    .wellness-btn-decline, .wellness-btn-decline:hover {
        height: 60px;
        width: 100%;
        color: #6244BB;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        border: 1px solid #6244BB;
        margin-right: 10px;
        padding: 10px;
        margin-bottom: 10px;
    }

}


    /* Small Screens */
    @media screen and (max-width: 767px) {
        .wd-30 {
            width: 80%;
        }

        .settings-button {
            width: 100%;
            height: 60px;
            padding: 17px 0;
        }

        .hide-on-small {
            display: none;
        }

        .employee-card {
            width: 90%;
            margin-bottom: 0;
        }

        .control-box {
            width: 90%;
            margin-top: 0;
            margin-bottom: 0;
        }

        .comment-box {
            width: 90%;
            margin-top: 0px;
        }
    }

    /* Medium Screens */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .wd-30 {
            width: 40%;
        }

        .hide-on-small {
            display: none;
        }

        .employee-card {
            width: 70%;
            margin-bottom: 0;
            height: 200px;
        }

        .control-box {
            width: 70%;
            margin-top: 0;
            margin-bottom: 0;
        }

        .comment-box {
            width: 70%;
            margin-top: 0;
        }
    }

    /* Large Screens */
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        .hide-on-large {
            display: none;
        }

        .empty-employee-card {
            width: 40%;
        }

        .employee-card {
            width: 40%;
            height: 180px;
        }

        .control-box {
            width: 35%;
        }

        .comment-box {
            width: 35%;
        }

        .control {
            margin: 4px 4px;
            font-size: 12px;
            position: relative;
            /*background-color: #B6B8DC;*/
            border: 1px solid transparent;
            border-radius: 0.25rem;
            padding: 0.375rem 0.375rem;
            cursor: pointer;
            width: 100%;
            text-align: center;
            height: 64px;
        }
    }

    /* Extra Large Screens */
    @media screen and (min-width: 1200px) {
        .hide-on-large {
            display: none;
        }

        .empty-employee-card {
            width: 30%;
        }

        .employee-card {
            width: 30%;
        }

        .control-box {
            width: 30%;
        }

        .comment-box {
            width: 30%;
        }
    }
